
import { defineComponent } from 'vue';
import store from '@/store';
import words from '@/datas/words.json';
import wordsTr from '@/datas/words-tr.json';
import targets from '@/datas/targets.json';
import targetsTr from '@/datas/targets-tr.json';
import en from '@/languages/en.json';
import tr from '@/languages/tr.json';

export interface Language {
    header: string,
    alertMessages: {
        congratulations: string,
        notInWordList: string,
        completeTheWord: string,
    },
    dialog: {
        congratulations: string,
        oneMoreGame: string,
    }
}

export default defineComponent({
    name: 'Wordle',
    data() {
        return {
            letters: [-2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2],
            guess: ['', '', '', '', '', ''],
            check: [
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
            ],
            words: words,
            en: en as Language,
            tr: tr as Language,
            target: '',
            targets: targets,
            row: 1,
            guessRight: false,
            alertMessage: '',
            alert: false,
            shakeRow: [false, false, false, false, false, false],
            modal: true,
            modalText: '',
        }
    },
    beforeMount() {
        this.restartGame();
    },
    created() {
        window.addEventListener('keydown', (e) => {
            e.preventDefault();
            if (this.row !== 7) {
                if (this.guess[this.row - 1].length < 5) {
                    if (((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123) || e.keyCode === 222) && this.language === 'en') {
                        this.addLetter(e.key);
                    } else if (((e.keyCode > 64 && e.keyCode < 91) || (e.keyCode > 96 && e.keyCode < 123) || e.keyCode === 186 || e.keyCode === 191 || (e.keyCode > 218 && e.keyCode < 223)) && this.language === 'tr') {
                        this.addLetter(e.key);
                    }
                }
                // enter
                if (e.keyCode === 13) {
                    this.submitGuess();
                }
                // backspace
                if (e.keyCode === 8 && this.guess[this.row - 1].length > 0) {
                    this.removeLetter();
                }
            } else {
                setTimeout(() => {
                    this.modal = true;
                }, 1000);
            }
        });
    },
    computed: {
        language() {
            return store.getters.language;
        },
        languages(): any {
            return {
                "en": en,
                "tr": tr
            }
        }
    },
    methods: {
        restartGame() {
            this.letters = [-2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2];
            this.guess = ['', '', '', '', '', ''];
            this.check = [
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
                [-2, -2, -2, -2, -2],
            ];
            if (this.language === 'tr') {
                this.words = wordsTr;
                this.targets = targetsTr;
            } else {
                this.words = words;
                this.targets = targets;
            }
            this.target = this.targets[Math.floor(Math.random() * this.targets.length)]
            this.row = 1;
            this.guessRight = false;
            this.modal = false;
        },
        setLanguage(lang: string) {
            store.commit('setLanguage', lang);
            this.restartGame();
        },
        addLetter(letter: string) {
            if (this.row === 7) {
                setTimeout(() => {
                    this.modal = true;
                }, 1000);
            }
            if (this.language === 'en') {
                this.guess[this.row - 1] = this.guess[this.row - 1] + letter.toUpperCase();
            } else {
                this.guess[this.row - 1] = this.guess[this.row - 1] + letter.toLocaleUpperCase('tr-TR');
            }
        },
        removeLetter() {
            if (this.row === 7) {
                setTimeout(() => {
                    this.modal = true;
                }, 1000);
            }
            this.guess[this.row - 1] = this.guess[this.row - 1].slice(0, -1);
        },
        submitGuess() {
            if (this.guess[this.row - 1].length < 5) {
                this.showAlert(false, this.languages[this.language].alertMessages.completeTheWord, 1000);
                return;
            }
            this.validateGuess(this.guess[this.row - 1]);
        },
        validateGuess(val: string) {
            if (this.language === 'en') {
                val = val.toLowerCase();
            } else {
                val = val.toLocaleLowerCase('tr-TR');
            }
            if (this.target === val) {
                this.check[this.row - 1] = [1, 1, 1, 1, 1];
                this.showAlert(true, this.languages[this.language].alertMessages.congratulations, 1000);
                this.guessRight = true;
                this.row = 7;
                for (let i = 0; i < 5; i++) {
                    if (val.charCodeAt(i) === 351) {
                        this.letters[26] = 1;
                    } else if (val.charCodeAt(i) === 246) {
                        this.letters[27] = 1;
                    } else if (val.charCodeAt(i) === 287) {
                        this.letters[28] = 1;
                    } else if (val.charCodeAt(i) === 231) {
                        this.letters[29] = 1;
                    } else if (val.charCodeAt(i) === 252) {
                        this.letters[30] = 1;
                    } else if (val.charCodeAt(i) === 105 && this.language === 'tr') {
                        this.letters[31] = 1;
                    } else if (val.charCodeAt(i) === 305 && this.language === 'tr') {
                        this.letters[8] = 1;
                    } else {
                        this.letters[val.charCodeAt(i) - 97] = 1;
                    }
                }
                setTimeout(() => {
                    this.modal = true;
                }, 1000);
                return;
            }
            if (this.row === 7) {
                setTimeout(() => {
                    this.modal = true;
                }, 1000);
            }
            const temp = this.target.split('');
            if (!this.words.includes(val)) {
                this.showAlert(false, this.languages[this.language].alertMessages.notInWordList, 1000);
                return;
            }
            for (let i = 0; i < 5; i++) {
                if (val.charCodeAt(i) === 351) {
                    this.letters[26] = this.letters[26] === -2 ? -1 : this.letters[26];
                } else if (val.charCodeAt(i) === 246) {
                    this.letters[27] = this.letters[27] === -2 ? -1 : this.letters[27];
                } else if (val.charCodeAt(i) === 287) {
                    this.letters[28] = this.letters[28] === -2 ? -1 : this.letters[28];
                } else if (val.charCodeAt(i) === 231) {
                    this.letters[29] = this.letters[29] === -2 ? -1 : this.letters[29];
                } else if (val.charCodeAt(i) === 252) {
                    this.letters[30] = this.letters[30] === -2 ? -1 : this.letters[30];
                } else if (val.charCodeAt(i) === 105 && this.language === 'tr') {
                    this.letters[31] = this.letters[31] === -2 ? -1 : this.letters[31];
                } else if (val.charCodeAt(i) === 305 && this.language === 'tr') {
                    this.letters[8] = this.letters[8] === -2 ? -1 : this.letters[8];
                } else {
                    this.letters[val.charCodeAt(i) - 97] = this.letters[val.charCodeAt(i) - 97] === -2 ? -1 : this.letters[val.charCodeAt(i) - 97];
                }
            }
            // validation logic
            temp.forEach((letter: string, index: number) => {
                let foundIndex = -1;
                for (let i = 0; i < 5; i++) {
                    if (letter === val.charAt(i) && i === index) {
                        if (foundIndex > -1) {
                            this.check[this.row - 1][foundIndex] = -2
                        }
                        if (val.charCodeAt(i) === 351) {
                            this.letters[26] = 1;
                        } else if (val.charCodeAt(i) === 246) {
                            this.letters[27] = 1;
                        } else if (val.charCodeAt(i) === 287) {
                            this.letters[28] = 1;
                        } else if (val.charCodeAt(i) === 231) {
                            this.letters[29] = 1;
                        } else if (val.charCodeAt(i) === 252) {
                            this.letters[30] = 1;
                        } else if (val.charCodeAt(i) === 105 && this.language === 'tr') {
                            this.letters[31] = 1;
                        } else if (val.charCodeAt(i) === 305 && this.language === 'tr') {
                            this.letters[8] = 1;
                        } else {
                            this.letters[val.charCodeAt(i) - 97] = 1
                        }
                        this.check[this.row - 1][i] = 1
                        break;
                    } else if (letter === val.charAt(i) && this.check[this.row - 1][i] === -2 && foundIndex === -1) {
                        foundIndex = i;
                        this.check[this.row - 1][i] = 0
                        if (val.charCodeAt(i) === 351) {
                            this.letters[26] = this.letters[26] === 1 ? 1 : 0;
                        } else if (val.charCodeAt(i) === 246) {
                            this.letters[27] = this.letters[27] === 1 ? 1 : 0;
                        } else if (val.charCodeAt(i) === 287) {
                            this.letters[28] = this.letters[28] === 1 ? 1 : 0;
                        } else if (val.charCodeAt(i) === 231) {
                            this.letters[29] = this.letters[29] === 1 ? 1 : 0;
                        } else if (val.charCodeAt(i) === 252) {
                            this.letters[30] = this.letters[30] === 1 ? 1 : 0;
                        } else if (val.charCodeAt(i) === 105 && this.language === 'tr') {
                            this.letters[31] = this.letters[31] === 1 ? 1 : 0;
                        } else if (val.charCodeAt(i) === 305 && this.language === 'tr') {
                            this.letters[8] = this.letters[8] === 1 ? 1 : 0;
                        } else {
                            this.letters[val.charCodeAt(i) - 97] = this.letters[val.charCodeAt(i) - 97] === 1 ? 1 : 0
                        }
                    }
                }
            });
            this.check[this.row - 1] = this.check[this.row - 1].map((el) => el === -2 ? -1 : el)
            if (this.row === 6) {
                setTimeout(() => {
                    this.modal = true;
                }, 1000);
            }
            this.row++;
        },
        showAlert(type: boolean, message: string, duration: number) {
            if (!type)
                this.shake();
            this.alertMessage = message
            this.alert = true;
            setTimeout(() => {
                this.alert = false;
            }, duration);
        },
        shake() {
            this.shakeRow[this.row] = true;
            setTimeout(() => {
                this.shakeRow[this.row] = false;
            }, 1000);
        }
    },
});
