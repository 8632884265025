
import { defineComponent } from 'vue';
import Wordle from './components/Wordle.vue';

export default defineComponent({
    name: 'App',
    components: {
        Wordle
    }
});
